<template>
	<div>
        <CustomTable
            id_table="semence_prestockage"
            ref="table"
            :items="lots"
            :busy.sync="table_busy"
            primaryKey="semencelot_id"
            :hide_if_empty="true"
            :hrefsRoutes="config_table_hrefs"
            :groupByCustom="customGroupBy"
            :externSlotColumns="['statut']"
        >
			<template v-slot:[`custom-slot-cell(statut)`]="{ data }">
				<a href="#" v-if="data.statut" @click.prevent="setUpControlsLot(data)">{{ $t('monte.congelation.statuts.'+data.statut) }}</a>
				<a href="#" v-else @click.prevent="setUpAddControlLot(data)">{{ $t('global.ajouter') }}</a>
			</template>
	    </CustomTable>

        <b-modal ref="modal-stock" hide-footer size="xl">
			<template v-slot:modal-title>
				{{ $t('monte.congelation.stockage_paillettes') }}
			</template>

			<table v-if="!hide_lot" class="table table-hover table-sm mb-0">
				<tr>
					<td>{{ $t('monte.stock_semence.numero_lot') }}</td>
					<td>{{ $t('monte.congelation.nb_paillettes') }}</td>
					<td>{{ $t('monte.congelation.cuve_stockage') }}</td>
				</tr>
				<template v-for="stallion in lots_selected">
					<tr :key="stallion.horse.horse_id">
						<td colspan="2"><b>{{ stallion.horse.horse_nom }}</b></td>
						<td>
							<e-select
								v-model="stallion.horse.stockage"
								id="stockage"
								track-by="full_name"
								label="full_name"
								:placeholder="$t('monte.stock_semence.rechercher_categorie')"
								:selectedLabel="$t('global.selected_label')"
								:options="all_localisations_stockage"
								:searchable="true"
								:show-labels="false"
								:allow-empty="true"
								:sortable="false"
								@input="changeStockageStallion(stallion.horse)"
							>
								<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.full_name }}</span></template>
							</e-select>
						</td>
					</tr>
					<tr v-for="lot in stallion.lots" :key="lot.semencelot_id">
						<td>{{ lot.semencelot_quantieme }}</td>
						<td>{{ lot.semencelot_qte }}</td>
						<td>
							<e-select
								v-model="lot.stockage"
								id="stockage"
								track-by="full_name"
								label="full_name"
								:placeholder="$t('monte.stock_semence.rechercher_categorie')"
								:selectedLabel="$t('global.selected_label')"
								:options="all_localisations_stockage"
								:searchable="true"
								:show-labels="false"
								:allow-empty="true"
								:sortable="false"
								@input="reloadLot"
							>
								<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.full_name }}</span></template>
							</e-select>
						</td>
					</tr>
				</template>
			</table>

			<div class="text-center">
				<a class="btn btn-primary mt-3" href="#" @click.prevent="checkForm">{{ $t('global.enregistrer') }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /></a>
			</div>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"
	import StockSemence from "@/mixins/StockSemence.js"
	import Navigation from "@/mixins/Navigation.js"
	import ShutterCongelation from "@/mixins/shutters-manager/Congelation.js"
	import _groupBy from 'lodash/groupBy'

	export default {
		name: 'PrestockageListe',
		mixins: [Congelation, StockSemence, Navigation, ShutterCongelation],
		data () {
			return {
				table_busy: false,
				lots: [],
				config_table_hrefs: {
					'horse.horse_nom': {
						routeUniqueName: 'StallionFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'horse.horse_id'
						}
					}
				},
				events_tab: {
					'TableAction::goToStockSemenceLot': this.openStockModal,
					'TableAction::goToControlLot': this.setUpAddControlLot
				},
				lots_selected: [],
				processing: false,
				all_localisations_stockage: [],
				hide_lot: false,
				customGroupBy: {'horse_max_date': this.horseNomDate},
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.table_busy = true
				this.lots = await this.getPrestockageLots()
				this.table_busy = false

				const all_localisations = await this.getLocalisationSemenceFullName()
				this.all_localisations_stockage = all_localisations.filter(loc => !loc.semencelocalisation_prestockage)
			},

			openStockModal(lots) {
				const lots_group = _groupBy(lots, 'horse.horse_id')
				const lots_selected = []
				for(let i in lots_group) {
					lots_group[i].forEach(lot => {
						lot.stockage = this.all_localisations_stockage.find(loc => loc.semencelocalisation_id == lot.stockage_id)
					})
					lots_selected.push({
						horse: {...lots_group[i][0].horse, stockage: lots_group[i][0].stockage},
						lots: lots_group[i]
					})
				}
				this.lots_selected = lots_selected
				this.$refs['modal-stock'].show()
			},

			reloadLot() {
				this.hide_lot = true
				this.$nextTick(()=> {
					this.hide_lot = false
				})
			},

			changeStockageStallion(horse) {
				let stallion_lots = this.lots_selected.findIndex(stallion => stallion.horse.horse_id == horse.horse_id)
				this.lots_selected[stallion_lots].lots.forEach(lots => {
					lots.stockage = horse.stockage
				})
				this.reloadLot()
			},

			async checkForm() {
				let lots = []
				this.lots_selected.forEach(lot_stallion => {
					const ll = lot_stallion.lots.map(lot => {
						return {
							semencelot_id: lot.semencelot_id,
							semencelot_date: new Date(),
							semencelot_qte: lot.semencelot_qte,
							semencelot_commentaire: lot.semencelot_commentaire,
							stockage: lot.stockage.semencelocalisation_id,
							old_stockage: lot.prestockage_id
						}
					})
					lots = lots.concat(ll)
				})

				this.processing = true
				await this.deplacementLots(lots)
				this.processing = false
				this.reload()
			},

			reload() {
				this.successToast('toast.info_save_succes')
				this.shutterPanel().close('add-control-lot')
				this.$refs['modal-stock'].hide()
				this.init_component()
				this.$refs.table.unselectAll()
			},

			horseNomDate(item) {
				let date = item.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/)
				return item.replace(date, '('+this.formatDate(date)+')')
			}
		},

		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
		}
	}

</script>